'use client'

import { usePathname } from "next/navigation";
import React, { createContext, useContext, useEffect, useState } from "react";

interface TutorialContextProps {
    step: number;
    steps: TutorialProps;
    tutorialDescriptions: TutorialDescriptionsProps;
    firstInteractionComplete: boolean;
    stepName: string;
    exitPath: string;
    keepCharged: boolean;
    setKeepCharged: (status: boolean) => void;
    setStep: (step: number) => void;
    setStepName: (name: string) => void;
    setExitPath: (name: string) => void;
    setFirstInteractionComplete: (status: boolean) => void;
}

interface TutorialProps {
    [key: string]: StepProps[];
}

interface TutorialDescriptionsProps {
    [key: string]: string;
}

interface StepProps {
    label: string;
    page?: string;
    onMenu?: boolean;
    option?: boolean;
    exitTutorial?: string;
    className: string;
    message: string;
    buttonMessage: string;
    nextStep: string;
    prevStep: string;
    tooltipPosition: string;
}

const DEFAULT_STEPS: TutorialProps = {
    "menu": [
        { label: "QR Code", page: "", onMenu: true, className: 'highlight-target-1', message: 'Use this to quickly check in at event sessions. Your QR Code is always accessible here for seamless entry.', tooltipPosition: "top", buttonMessage: "Next: Profile & Settings", nextStep: "", prevStep: "" },
        { label: "Profile & Settings", onMenu: true, className: 'highlight-target-2', message: 'Manage your personal information, tags, and account settings. This is where you can update your profile to make it easier for others to find you.', tooltipPosition: "center", buttonMessage: "Next: A.I. Assistant", nextStep: "", prevStep: "" },
        { label: "A.I. Assistant", onMenu: true, className: 'highlight-target-3', message: 'Anytime you need help, just tap here to interact with me. I’m here to make sure your event experience is smooth and enjoyable.', tooltipPosition: "center", buttonMessage: "Next: Your Schedule", nextStep: "", prevStep: "" },
        { label: "Your Schedule", onMenu: true, className: 'highlight-target-4', message: 'All the sessions, meetings, and networking events you’ve saved will appear here. It’s your personal event planner.', tooltipPosition: "bottom", buttonMessage: "Next: Deals", nextStep: "", prevStep: "" },
        { label: "Deals", onMenu: true, className: 'highlight-target-5', message: 'Explore business opportunities and track My deals. Whether you’re looking for hot deals or managing ongoing ones, this is where it happens.', tooltipPosition: "bottom", buttonMessage: "Next: Messages", nextStep: "", prevStep: "" },
        { label: "Messages", onMenu: true, className: 'highlight-target-6', message: 'All your event-related conversations are stored here. Stay connected with other attendees and follow up on important discussions.', tooltipPosition: "top", buttonMessage: "Next: Connections", nextStep: "", prevStep: "" },
        { label: "Connections", onMenu: true, className: 'highlight-target-7', message: 'Manage and view all your networking contacts here. It’s easy to keep track of who you’ve met and follow up after the event.', tooltipPosition: "top", buttonMessage: "Next: Dashboard", nextStep: "", prevStep: "" },
        { label: "Dashboard", onMenu: true, className: 'highlight-target-8', message: 'This is your overview of everything going on. Keep tabs on your schedule, deals, and more all from one central location..', tooltipPosition: "top", buttonMessage: "Next: Help & Tutorial", nextStep: "", prevStep: "" },
        { label: "Help & Tutorial", onMenu: true, className: 'highlight-target-9', message: 'Need guidance? Go through this onboarding agian. I’m also available to answer any questions directly.', tooltipPosition: "top", buttonMessage: "Finish", nextStep: "", prevStep: "" },
    ],
    "menu_1": [
        { label: "QR Code", page: "", onMenu: true, className: 'highlight-target-1', message: 'Use this to quickly check in at event sessions. Your QR Code is always accessible here for seamless entry.', tooltipPosition: "top", buttonMessage: "Return", nextStep: "", prevStep: "" }
    ],
    "menu_2": [
        { label: "Profile & Settings", onMenu: true, className: 'highlight-target-2', message: 'Manage your personal information, tags, and account settings. This is where you can update your profile to make it easier for others to find you.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "" },
    ],
    "menu_3": [
        { label: "A.I. Assistant", onMenu: true, className: 'highlight-target-3', message: 'Anytime you need help, just tap here to interact with me. I’m here to make sure your event experience is smooth and enjoyable.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "" },
    ],
    "menu_4": [
        { label: "Your Schedule", onMenu: true, className: 'highlight-target-4', message: 'All the sessions, meetings, and networking events you’ve saved will appear here. It’s your personal event planner.', tooltipPosition: "bottom", buttonMessage: "Return", nextStep: "", prevStep: "" },
    ],
    "menu_5": [
        { label: "Deals", onMenu: true, className: 'highlight-target-5', message: 'Explore business opportunities and track My deals. Whether you’re looking for hot deals or managing ongoing ones, this is where it happens.', tooltipPosition: "bottom", buttonMessage: "Return", nextStep: "", prevStep: "" },
    ],
    "menu_6": [
        { label: "Messages", onMenu: true, className: 'highlight-target-6', message: 'All your event-related conversations are stored here. Stay connected with other attendees and follow up on important discussions.', tooltipPosition: "top", buttonMessage: "Return", nextStep: "", prevStep: "" },
    ],
    "menu_7": [
        { label: "Connections", onMenu: true, className: 'highlight-target-7', message: 'Manage and view all your networking contacts here. It’s easy to keep track of who you’ve met and follow up after the event.', tooltipPosition: "top", buttonMessage: "Return", nextStep: "", prevStep: "" },
    ],
    "menu_8": [
        { label: "Dashboard", onMenu: true, className: 'highlight-target-8', message: 'This is your overview of everything going on. Keep tabs on your schedule, deals, and more all from one central location..', tooltipPosition: "top", buttonMessage: "Return", nextStep: "", prevStep: "" },
    ],
    "menu_9": [
        { label: "Help & Tutorial", onMenu: true, className: 'highlight-target-9', message: 'Need guidance? Go through this onboarding agian. I’m also available to answer any questions directly.', tooltipPosition: "top", buttonMessage: "Return", nextStep: "", prevStep: "" },
    ],
    "event": [
        { label: "QR Code", page: "/event?id=2", className: 'highlight-event-1', message: 'Your QR Code is displayed here for quick access. Use it to check in to sessions effortlessly.', tooltipPosition: "center", buttonMessage: "Next", nextStep: "", prevStep: "", exitTutorial: "/help" },
        { label: "Add to Schedule", className: 'highlight-event-2', message: 'Found something interesting? Click here to add it to your schedule. Keep track of all the events you don’t want to miss.', tooltipPosition: "center", buttonMessage: "Next", nextStep: "", prevStep: "", exitTutorial: "/help" },
        { label: "Your Schedule", className: 'highlight-event-3', message: 'Access your planned events and sessions directly from the dashboard. This keeps everything organised and at your fingertips.', tooltipPosition: "center", buttonMessage: "Finish", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "event_1": [
        { label: "QR Code", page: "/event?id=2", className: 'highlight-event-1', message: 'Your QR Code is displayed here for quick access. Use it to check in to sessions effortlessly.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "event_2": [
        { label: "Add to Schedule", page: "/event?id=2", className: 'highlight-event-2', message: 'Found something interesting? Click here to add it to your schedule. Keep track of all the events you don’t want to miss.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "event_3": [
        { label: "Your Schedule", page: "/event?id=2", className: 'highlight-event-3', message: 'Access your planned events and sessions directly from the dashboard. This keeps everything organised and at your fingertips.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "profile": [
        { label: "Tags", page: "/profile/overview-profile", className: 'highlight-profile-1', message: 'Add tags to your profile to highlight your interests and expertise. This makes it easier for others to connect with you.', tooltipPosition: "center", buttonMessage: "Next", nextStep: "", prevStep: "", exitTutorial: "/help" },
        { label: "Details/Description", className: 'highlight-profile-2', message: 'Update your profile description to reflect who you are and what you’re looking for at the event. It’s your personal introduction to other attendees.', tooltipPosition: "center", buttonMessage: "Next", nextStep: "", prevStep: "", exitTutorial: "/help" },
        { label: "Account Settings", className: 'highlight-profile-3', message: 'Manage your account details, privacy settings, and more. Customise your experience to suit my needs.', tooltipPosition: "center", buttonMessage: "Finish", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "profile_1": [
        { label: "Tags", page: "/profile/overview-profile", className: 'highlight-profile-1', message: 'Add tags to your profile to highlight your interests and expertise. This makes it easier for others to connect with you.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "profile_2": [
        { label: "Details/Description", page: "/profile/overview-profile", className: 'highlight-profile-2', message: 'Update your profile description to reflect who you are and what you’re looking for at the event. It’s your personal introduction to other attendees.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "profile_3": [
        { label: "Account Settings", page: "/profile/overview-profile", className: 'highlight-profile-3', message: 'Manage your account details, privacy settings, and more. Customise your experience to suit my needs.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "schedule": [
        { label: "", page: "/schedule", className: 'highlight-schedule-1', message: 'This is your go-to for keeping track of all your planned activities. From sessions to networking events, everything you’ve added is here for easy access and management.', tooltipPosition: "center", buttonMessage: "Finish", nextStep: "", prevStep: "", exitTutorial: "/help" }
    ],
    "deals": [
        { label: "My deals", page: "/deals", className: 'highlight-deals-1', message: 'This section is all about managing the deals you’re involved in.', tooltipPosition: "center", buttonMessage: "Next: All Deals", nextStep: "/deals/all", prevStep: "", exitTutorial: "/help" },
        { label: "All Deals", className: 'highlight-deals-2', message: 'Discover new business opportunities here. Use filters and sorting options to find deals that match your interests.', tooltipPosition: "center", buttonMessage: "Next: Curated Deals", nextStep: "/deals/curated", prevStep: "", exitTutorial: "/help" },
        { label: "Curated Deals", className: 'highlight-deals-3', message: 'Explore deals that have been specially selected as exceptional. You can love deals that catch your eye to get the deal makers on stage.', tooltipPosition: "center", buttonMessage: "Finish", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "deals_1": [
        { label: "My deals", page: "/deals", className: 'highlight-deals-1', message: 'This section is all about managing the deals you’re involved in.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "/deals/all", prevStep: "", exitTutorial: "/help" },
    ],
    "deals_2": [
        { label: "All Deals", page: "/deals/all", className: 'highlight-deals-2', message: 'Discover new business opportunities here. Use filters and sorting options to find deals that match your interests.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "deals_3": [
        { label: "Curated Deals", page: "deals/curated", className: 'highlight-deals-3', message: 'Explore deals that have been specially selected as exceptional. You can love deals that catch your eye to get the deal makers on stage.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "messages": [
        { label: "", page: "/messages", className: 'highlight-messages-1', message: 'Keep all your event-related communications in one place. Whether you’re chatting with a new connection or following up on a deal, you’ll find it all here.', tooltipPosition: "center", buttonMessage: "Finish", nextStep: "", prevStep: "", exitTutorial: "/help" }
    ],
    "connections": [
        { label: "", page: "/connections", className: 'highlight-connections-1', message: 'Manage your networking contacts here. See who you’ve met, follow up with connections, and explore profiles to build lasting professional relationships.', tooltipPosition: "center", buttonMessage: "Finish", nextStep: "", prevStep: "", exitTutorial: "/help" }
    ],
    "dashboard": [
        { label: "", page: "/dashboard", className: 'highlight-dashboard-1', message: 'Your central hub for everything going on at the event. View your schedule, deals, and other important information at a glance.', tooltipPosition: "center", buttonMessage: "Finish", nextStep: "", prevStep: "", exitTutorial: "/help" }
    ],
    "meetings": [
        { label: "Meetings Schedule", page: "/meetings", className: 'highlight-meetings-1', message: 'All your scheduled meetings are displayed here.', tooltipPosition: "center", buttonMessage: "Next", nextStep: "/meetings/meeting-recording-example", prevStep: "", exitTutorial: "/help" },
        { label: "Meeting Page", className: 'highlight-meetings-2', message: 'During a meeting, access all the necessary details, including participant information and options to confirm attendance, record the session, or pause as needed.', tooltipPosition: "top", buttonMessage: "Next", nextStep: "", prevStep: "/meetings", exitTutorial: "/help" },
        { label: "Participants", className: 'highlight-meetings-3', message: 'See who is attending the meeting. You can easily connect with participants and manage attendance.', tooltipPosition: "center", buttonMessage: "Next", nextStep: "", prevStep: "", exitTutorial: "/help" },
        { label: "Buttons", className: 'highlight-meetings-4', message: 'These controls help you manage your meeting experience.', tooltipPosition: "center", buttonMessage: "Next: post-meeting", nextStep: "/meetings/meeting-example", prevStep: "", exitTutorial: "/help" },
        { label: "Transcript", className: 'highlight-meetings-5', message: 'Review the transcript of your meeting here. It’s a convenient way to go over important points discussed.', tooltipPosition: "bottom", buttonMessage: "Next", nextStep: "", prevStep: "/meetings/meeting-recording-example", exitTutorial: "/help" },
        { label: "Audio Recording", className: 'highlight-meetings-6', message: 'Access the audio recording of your meeting. Listen back to any discussion to ensure nothing is missed.', tooltipPosition: "top", buttonMessage: "Finish", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "speedNetwork": [
        { label: "Table no. & Time", page: "/speed-network/example", className: 'highlight-speed-network-1', message: 'See where and when your networking sessions are happening. It’s easy to keep track of your speed networking schedule.', tooltipPosition: "center", buttonMessage: "Next", nextStep: "", prevStep: "", exitTutorial: "/help" },
        { label: "Options", className: 'highlight-speed-network-2', message: 'Open profiles of those you’re about to meet, or skip a talk if it’s not relevant to you. You’re in control.', tooltipPosition: "center", buttonMessage: "Next: Meeting", nextStep: "/speed-network/recording-example", prevStep: "", exitTutorial: "/help", option: true },
        { label: "Time Left", className: 'highlight-speed-network-3', message: 'Keep an eye on how much time you have left in each session. This helps you make the most of your networking opportunities.', tooltipPosition: "center", buttonMessage: "Next", nextStep: "", prevStep: "/speed-network/example", exitTutorial: "/help" },
        { label: "AI Suggestions", className: 'highlight-speed-network-4', message: 'Get real-time suggestions from the AI on how to steer your conversation or make a connection more meaningful.', tooltipPosition: "top", buttonMessage: "Next", nextStep: "", prevStep: "", exitTutorial: "/help" },
        { label: "Buttons", className: 'highlight-speed-network-5', message: 'These buttons ensure we stay on track with note taking.', tooltipPosition: "center", buttonMessage: "Finish", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "speedNetwork_1": [
        { label: "Table no. & Time", page: "/speed-network/example", className: 'highlight-speed-network-1', message: 'See where and when your networking sessions are happening. It’s easy to keep track of your speed networking schedule.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "speedNetwork_2": [
        { label: "Options", page: "/speed-network/example", className: 'highlight-speed-network-2', message: 'Open profiles of those you’re about to meet, or skip a talk if it’s not relevant to you. You’re in control.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help", option: true },
    ],
    "speedNetwork_3": [
        { label: "Time Left", page: "/speed-network/recording-example", className: 'highlight-speed-network-3', message: 'Keep an eye on how much time you have left in each session. This helps you make the most of your networking opportunities.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "speedNetwork_4": [
        { label: "AI Suggestions", page: "/speed-network/recording-example", className: 'highlight-speed-network-4', message: 'Get real-time suggestions from the AI on how to steer your conversation or make a connection more meaningful.', tooltipPosition: "top", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "speedNetwork_5": [
        { label: "Buttons", page: "/speed-network/recording-example", className: 'highlight-speed-network-5', message: 'These buttons ensure we stay on track with note taking.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "handsFree": [
        { label: "Command Log & “Expand”", page: "/", className: 'highlight-handsFree-1', message: 'View the commands you’ve issued and expand the log to see more details. This helps you keep track of your interactions with the app.', tooltipPosition: "top", buttonMessage: "Next", nextStep: "", prevStep: "", exitTutorial: "/help" },
        { label: "Play/Pause & Exit Buttons", className: 'highlight-handsFree-2', message: 'Control your hands-free experience with these buttons. Play or pause your interaction, and exit when you’re done.', tooltipPosition: "center", buttonMessage: "Finish", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "handsFree_1": [
        { label: "Command Log & “Expand”", page: "/", className: 'highlight-handsFree-1', message: 'View the commands you’ve issued and expand the log to see more details. This helps you keep track of your interactions with the app.', tooltipPosition: "top", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ],
    "handsFree_2": [
        { label: "Play/Pause & Exit Buttons", page: "/", className: 'highlight-handsFree-2', message: 'Control your hands-free experience with these buttons. Play or pause your interaction, and exit when you’re done.', tooltipPosition: "center", buttonMessage: "Return", nextStep: "", prevStep: "", exitTutorial: "/help" },
    ]
}

const DEFAULT_CATEGORY_DESCRIPTIONS: TutorialDescriptionsProps = {
    "menu": "The Menu is your central hub for navigating the app. From here, you can easily access essential features like your Profile & Settings, Your Schedule, Deals, Messages, Connections, and more. You’ll also find a QR Code for quick event check-ins and a shortcut to the A.I. Assistant, who is always ready to help you with any questions or tasks. If you ever need guidance, just tap on “Help & Tutorial” for step-by-step instructions.",
    "event": "The Event Dashboard is where you can see all event-related activities at a glance. Here, you can quickly add sessions to your schedule with the “Add to Schedule” button and keep track of everything you’ve planned under “Your Schedule.” You can also access your QR Code for easy entry to sessions. If you need assistance, the A.I. Assistant is just a click away.",
    "profile": "The Profile & Settings section allows you to manage your personal information and account details. Add tags to make it easier for others to find you, and update your description to highlight your expertise and interests. You can also adjust your account settings here to personalise your experience. For additional support, feel free to ask the A.I. Assistant.",
    "schedule": "The Your Schedule section is your personal event planner. All the sessions, meetings, and networking events you’ve added will appear here, helping you manage your time efficiently. You can easily access details for each event and make changes as needed. If you have any questions about your schedule, the A.I. Assistant is always available to assist.",
    "deals": "The Deals feature is tailored to help you discover and manage business opportunities with other event attendees. In the “My deals” section, you can learn how deals work and track any deals you’ve initiated or are involved in. The “All Deals” section offers powerful filters and sorting options to help you find the most relevant deals, including “Hot Deals” and “Trending” offers. Additionally, “Curated Deals” allows you to explore specially selected opportunities, where you can love deals that catch your interest. This feature ensures you can efficiently connect with potential business partners and close deals that matter to you.",
    "messages": "The Messages feature keeps all your event-related communications in one place. Here, you can chat with other attendees, follow up on deals, and stay in touch with connections you’ve made. For any help with messaging, just ask the A.I. Assistant.",
    "connections": "The Connections section helps you manage your networking contacts. View profiles, follow up on new connections, and keep track of who you’ve met during the event. The A.I. Assistant can guide you through making the most of your connections.",
    "dashboard": "Your central hub for everything going on at the event. View your schedule, deals, and other important information at a glance.",
    "meetings": "The Meetings & Recordings feature ensures you never miss out on important discussions. Your “Meetings Schedule” keeps track of all your appointments, and during meetings, you can confirm attendance, record sessions, or pause as needed. Post-meeting, you can access transcripts and recordings for easy review. If you need assistance, the A.I. Assistant is here to help.",
    "speedNetwork": "The **Speed Networking** feature is designed to maximize your networking efficiency. You can view your table number and scheduled time, and during the session, keep track of time left and get AI-powered suggestions. If you’re ready to move on, you can easily skip to the next conversation. The A.I. Assistant is available for any support you might need during your networking sessions.",
    "handsFree": "The Hands-free Mode allows you to interact with the app using voice commands, making it easier to multitask. You’ll find a Command Log and the option to expand it, along with Play/Pause and Exit buttons for control. If you need help with commands, just ask the A.I. Assistant, who is always ready to assist.",
}

const TutorialContext = createContext<TutorialContextProps | undefined>(undefined);

export function useTutorial() {
    const context = useContext(TutorialContext);
    if (!context) {
        throw new Error('useTutorial must be used within a TutorialProvider');
    }
    return context;
}

export const TutorialProvider = ({ children }: { children: React.ReactNode }) => {
    const DEFAULT_STEP = 999
    const DEFAULT_TUTORIAL = ""
    const [firstInteractionComplete, setFirstInteractionComplete] = useState(false);
    const [exitPath, setExitPath] = useState<string>("");
    const [keepCharged, setKeepCharged] = useState(false);
    const [steps, setSteps] = useState<TutorialProps>(DEFAULT_STEPS);
    const [tutorialDescriptions, setTutorialDescriptions] = useState<TutorialDescriptionsProps>(DEFAULT_CATEGORY_DESCRIPTIONS);
    const [step, setStep] = useState(DEFAULT_STEP);
    const [stepName, setTutorial] = useState(DEFAULT_TUTORIAL);
    const pathName = usePathname();

    useEffect(() => {
        const stepStorage = localStorage.getItem("step")
        if (!stepStorage) return
        localStorage.setItem("step", step.toString())
    }, [step])

    useEffect(() => {
        localStorage.setItem("tutorial", stepName.toString())
    }, [stepName])

    useEffect(() => {
        const stepStorage = localStorage.getItem("step")
        if (stepStorage) {
            setStep(parseInt(stepStorage))
        } else {
            localStorage.setItem("tutorial", DEFAULT_TUTORIAL.toString())
            localStorage.setItem("step", DEFAULT_STEP.toString())
        }
    }, [pathName])

    const setStepName = (name: string) => {
        setStep(0)
        setTutorial(name)
        setFirstInteractionComplete(false)
    }

    return (
        <TutorialContext.Provider value={{ setStepName, setStep, setFirstInteractionComplete, setExitPath, keepCharged, setKeepCharged, exitPath, stepName, step, steps, tutorialDescriptions, firstInteractionComplete }}>
            {children}
        </TutorialContext.Provider>
    );
};